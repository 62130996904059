.cmp_copy {
    padding-left: 0.75rem;
}

.cmp_copy .button {
    height: auto;
}

.cmp_copy.anchor {
    padding-left: 0;
}

.cmp_copy.anchor .tertiary {
    font-weight: normal;
    text-decoration: underline;
}