#breadcrumb li {
    list-style-type: none;
}

#breadcrumb ol {
    display: flex;
    padding: 0;
    margin-top: 0;
}

#breadcrumb button {
    border: none;
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
    align-items: start;
    color: var(--color-primary-500);
    height: auto;
    border-radius: 0;
}

@media only screen and (max-width: 53.125rem) {

    #breadcrumb {
      display: none;
    }
}