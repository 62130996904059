#api__tab__logs .datepicker_group,
#api__tab__logs .dropdown_group {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 1rem;
}

#api__tab__logs .content_filter {
    flex-direction: row;
    padding-bottom: 1.15rem;
}

@media only screen and (max-width: 78.125rem) {

    #api__tab__logs .content_filter {
        flex-direction: column;
        padding-bottom: 0;
    }

    #api__tab__logs .datepicker_group {
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

}