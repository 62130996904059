#mdl_addlanguage {
    min-width: unset;
}
/*  Modal =================================================================================================== */

.language__modal {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 0.375rem;
  max-width: 52rem;
  min-width: 47rem;
  overflow: auto;
  margin: 1.5rem 0;
}