#organizations_add .icon__checkmark {
    width: 32px;
    height: 32px;
    color: var(--color-green-500);
}

#organizations_add select{
    font-size: medium;
    border-color: lightgray;
    border-radius: 8px;
    padding: 0px 10px 0px;
    height: 38px;
    width: 312px;
    color: #9CA3AF;
}

#organizations_add select.active{
    color: var(--color-gray-900);
}

#organizations_add select > option:not(:first-child){
    color: var(--color-gray-900);
}

#organizations_add select.active > option:first-child{
    color: #9CA3AF;
}

#organizations_add .field-dropdown{
    margin-top: auto;
}