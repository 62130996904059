#crd__self-verified-credentials .datepicker_group,
#crd__self-verified-credentials .dropdown_group {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 1rem;
}

#crd__self-verified-credentials .content_filter {
    flex-direction: row;
    padding-bottom: 1.15rem;
}

@media only screen and (max-width: 78.125rem) {

    #crd__self-verified-credentials .content_filter {
        flex-direction: column;
        padding-bottom: 0;
    }

    #crd__self-verified-credentials .datepicker_group {
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

}