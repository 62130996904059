
#organizations_details__tab__details select{
    font-size: medium;
    border-color: lightgray;
    border-radius: 8px;
    padding: 0px 10px 0px;
    height: 38px;
    width: 312px;
    color: #9CA3AF;
}

#organizations_details__tab__details select.active{
    color: var(--color-gray-900);
}

#organizations_details__tab__details select > option:not(:first-child){
    color: var(--color-gray-900);
}

#organizations_details__tab__details select.active > option:first-child{
    color: #9CA3AF;
}

#organizations_details__tab__details .field-dropdown{
    margin-top: auto;
}

