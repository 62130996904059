.form_features .detailsgroup__subgroup {
    background-color: unset;
    max-width: 38rem;
    padding: 0;
}

.form_features .detailsgroup__subgroup .detailsgroup__subgroup__item {
    flex-direction: row;
    padding: 0.8125rem 1.0625rem 0.75rem 0.8125rem;
    gap: 0.5rem;
    align-items: flex-start;
    border-bottom: 1px solid var(--color-gray-200);
}

.form_features .detailsgroup__subgroup .detailsgroup__subgroup__item:last-of-type {
    border-bottom: none;
}

.form_features .detailsgroup__subgroup .detailsgroup__subgroup__item .detailsgroup__subgroup__item__labeltwoline {
    flex-direction: column;
}

.form_features .detailsgroup__subgroup .detailsgroup__subgroup__item .field {
    flex-grow: unset;
}