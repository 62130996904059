@import "./ui_credivera.css";
@import "./ui_semantic.css";

body {
  min-height: 100vh;
  /*min-width: 100vw;*/
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  color: var(--color-gray-900);
  background-color: var(--color-gray-100);
}

div {
  display: flex;
}

h1 {
  margin-bottom: 1.5rem;
}

#root {
  width: 100%;
  flex-grow: 1;
}

.app {
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
}

.offline {
  display: flex;
  flex-direction: row !important;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-100);
}

.offline main {
  width: 100%;
}

.systemalert {
  background-color: var(--color-orange-500);
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

.crd_systemalert_wrapper {
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.crd_systemalert {
  width: 300px;
  border: 1px solid var(--color-gray-200);
  padding: 28px;
  border-radius: 5px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crd_systemalert_text {
  font-size: 16px;
  font-weight: 500;
}

.crd_systemalert_timeframe {
  padding-top: 24px;
  font-size: 14px;
  font-weight: 400;
}


#skip {
  width: 100%;
  display: contents;
}

#skip a {
  position:absolute;
  left:0px;
  top:-40px;
  height:1px;
  overflow:hidden;
  background-color: var(--color-primary-500);
  color: var(--color-white);
  padding-left: 16px;
  font-weight: 400;
  font-size: 1rem;
}

#skip a:focus {
  flex-basis: 100%;
  position:static;
  width:auto;
  height: 2.5em;
  line-height: 2.5em;
  outline-offset: -0.2rem;
}

/*  NAVIGATION ====================================================================================================== */


.offline .nav {
  display: none;
}

.nav {
  background-color: var(--color-primary-700);
  color: var(--color-gray-100);
  min-width: 15.9375rem;
  flex-direction: column;
}

.nav__header__portal.text--xl-bold {
  font-weight: 600;
}

.nav .text--md-medium {
  font-weight: 400;
}

.nav .text--xs-regular {
  font-weight: 300;
}

.nav .profile_email {
  max-width: 10.375rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav__header {
  flex-direction: row;
  padding: 0.75rem 1.5rem;
}

.nav__header__hamburger {
  display: none;
  background-color: var(--color-primary-800);
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 0.375rem;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-primary-500);
}

.nav__header__hamburger img {
  width: 1rem;
  height: 0.75rem;
  margin: 0;
  padding: 0;
}

.nav__header__portal {
  align-items: center;
}

.nav__header__portal img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.nav__expanded {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
}

.nav.nav__expanded--display .nav__expanded {
  display: flex;
}

.nav.nav__expanded--hide .nav__expanded {
  display: flex;
}

main {
  width: calc(100% - 15.9375rem);
}

.cont.nav__expanded--display {
  display: flex;
}

.cont.nav__expanded--hide {
  display: flex;
}

.nav__expanded__options {
  flex-direction: column;
  padding-bottom: 1.75rem;
}

.nav__expanded__option {
  cursor: pointer;
  align-items: center;
  padding: .8125rem .8125rem .8125rem 2rem;
}

.nav__expanded__option:focus-visible {
  outline-offset: -0.2rem;
}

.nav__expanded__option img {
  margin-right: .8125rem;
}

.nav__expanded__option.active {
  background-color: var(--color-primary-800);
}

.nav__expanded__option:hover {
  background-color: var(--color-primary-800);
}

.nav__expanded__option__textwrapper {
  display: flex;
  flex-direction: column;
}

.nav__expanded__divider {
  border-top: 1px solid var(--color-primary-500);
  margin: 1.5rem;
}

.nav__expanded__account {
  flex-direction: column;
}



/*  CONTENT ========================================================================================================= */

.cont {
  flex-grow: 1;
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  padding: 1.563rem 1.875rem 1.563rem 1.875rem;
}

.page__header {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(var(--color-blue-100), var(--color-blue-100));
  background-size: 100% calc(100% - 3rem);
  background-repeat: no-repeat;
  margin: -1.563rem -1.875rem 1.5rem -1.875rem;
  padding: 1.563rem 1.875rem 0 1.875rem;
}

.page__header--background2 {
  background-size: 100% 100%;
  padding-bottom: 3rem;
  margin-bottom: -2.5rem;
}

.page__header--background3 {
  background-size: 100% 100%;
  padding-bottom: 12rem;
  margin-bottom: -10.5rem;
}

.page__back {
  display: none;
  color: var(--color-white);
  margin-bottom: 1.5rem;
  align-items: center;
}

.page__back .icon__page__back {
  margin-right: 0.519rem;
  height: 0.75rem;
  width: 0.463rem;
}


/* breadcrumbs ------------------------------------------------------------------------------------------------------ */

.page__breadcrumb {
  align-items: center;
  margin-bottom: 1.5rem;
}

.page__breadcrumb__icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: var(--color-primary-500);
}

.page__breadcrumb__item {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--color-primary-500);
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.page__breadcrumb > .page__breadcrumb__item:not(.current):after {
  cursor: default;
  color: var(--color-gray-400);
  font-size: 1rem;
  margin: 0 .6rem;
  content: '/';
}

.page__breadcrumb__item.current,
.page__breadcrumb__item.inactive {
  color: var(--color-gray-700);
  cursor: default;
}

/* accordion -------------------------------------------------------------------------------------------------------- */

.accordion__chevron--right {
  align-self: center;
  cursor: pointer;
}

.accordion__chevron--right svg {
  height: 0.75rem;
  width: 0.75rem;
}

/* launch cards ----------------------------------------------------------------------------------------------------- */

.launchcard__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0.75rem 0 1.5rem 0;
  row-gap: 0.75rem;
  column-gap: 1rem;
}

.launchcard {
  background: var(--color-gray-200);
  width: 16rem;
  height: 4.25rem;
  border-radius: 0.375rem;
  padding: 0.75rem;
  cursor: pointer;
  flex-direction: row;
}

.launchcard--green {
  background: var(--color-green-700);
}

.launchcard--red {
  background: var(--color-red-700);
}

.launchcard--blue {
  background: var(--color-primary-500);
}

.launchcard--gray {
  background: var(--color-gray-500);
}

.launchcard__icon {
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  min-width: 1.75rem;
  color: var(--color-white);
}

.launchcard__img {
  justify-content: center;
  align-items: center;
  max-width: 3.125rem;
}

.launchcard__img img {
  width: 100%;
}

.launchcard__text {
  flex-direction: column;
  justify-content: center;
  padding: 0 0.75rem;
  width: calc(100% - 3.75rem);
  color: var(--color-white);
}

.launchcard__text .text--sm-medium,
.launchcard__text .text--sm-regular,
.launchcard__text .text--md-medium {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.launchcard__chevron {
  width: 0.475rem;
  color: var(--color-white);
  align-self: center;
  margin-left: auto;
}


/* tabs ------------------------------------------------------------------------------------------------------------- */

.tab {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  border-bottom: 1px solid var(--color-gray-300);
  margin-bottom: 1.5rem;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.tab::-webkit-scrollbar {
  display: none;
}

.tab__item {
  padding: 0 0.25rem 0.625rem 0.25rem;
  margin-right: 2.5rem;
  color: var(--color-primary-500);
  cursor: pointer;
}

.tab__item:focus-visible {
  outline-offset: -0.188rem;
}
.tab__item.current {
  cursor: default;
  padding: 0 0.25rem;
  margin-right: 2.5rem;
  color: var(--color-gray-900);
  border-bottom: 2px solid var(--color-gray-900);
}


/* cards ------------------------------------------------------------------------------------------------------------ */

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  box-sizing: border-box;
  background-color: var(--color-white);
  margin-bottom: 1.25rem;
  min-width: 21.4375rem;
}


.card .icon--lg--center {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.card .icon--lg--center img {
  width: 4.125rem;
  height: 4.125rem;
}

.offline .card {
  width: 28rem;
}

.offline .logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.offline .logo img {
  width: 12.5rem;
}

.offline .display--sm-medium {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
  color: var(--color-gray-900)
}

.offline .display--xs-medium {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
  color: var(--color-gray-900)
}

.offline .text--sm-regular {
  margin-top: 1rem;
  color: var(--color-gray-700)
}

.card__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

.card__header__icon {
  margin-right: 1rem;
}

.card__header__icon .svg {
  width: 2rem;
  height: 2rem;
  color: var(--color-green-500)
}

.card__header__icon__step {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
}

.card__header__icon__step.icon--dark_gray {
  color: var(--color-white);
  background-color: var(--color-secondary-800);
}

.card__header__icon__step.icon--light_gray {
  color: var(--color-secondary-700);
  background-color: var(--color-secondary-100);
}

.card__header__left {
  display: flex;
  flex-direction: row;
}

.card__header__left.text {
  flex-direction: column;
}

.card__header__left .text--xl-medium {
  color: var(--color-gray-900);
}

.card .text--sm-regular {
  color: var(--color-gray-600);
}

.card__header__right {
  display: flex;
  margin-left: auto;
  padding-left: 1rem;
}

.card__header__inactive {
  margin-bottom: 0;
}

.card__header .stepno {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  margin-right: 1rem;
  min-width: 2rem;
}

.card__header .stepno.green-500 {
  background-color: var(--color-green-500);
}

.card__header .stepno.secondary-800 {
  background-color: var(--color-secondary-800);
}

.card__content {
  display: flex;
  flex-direction: column;
}

.content__left {
  display: flex;
  flex-direction: column;
}

.content__right {
  display: flex;
  margin-left: auto;
  padding-left: 1rem;
}

.content__description {
  max-width: 53.125rem;
}

.detailsgroup.descriptive .field {
  flex-grow: revert;
}

.detailsgroup.descriptive .field .checkbox {
  width: 14rem;
}


.detailsgroup input.file__upload {
  max-width: 13.125rem;
}

.card__complete {
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  color: var(--color-gray-500);
}

.card__footer {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  border-top: 1px solid var(--color-gray-200);
}

.card__footer--mobilebtns {
  padding-top: 2rem;
  display: none;
}

.card__footer .card__footer--mobilebtns {
  padding-top: 0;
}

.hr {
  width: 100%;
  height: 1px;
  background: var(--color-gray-200);
  margin-bottom: 0.75rem;
}

.login_wrapper,
.login {
  width: 100%;
  justify-content: center;
}

/*  textbox --------------------------------------------------------------------------------------------------------- */

.textbox__wrapper {
  width: 30.75rem;
  flex-direction: column;
}


/*  preloader ------------------------------------------------------------------------------------------------------- */

.preloader__wrapper {
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1003;
  background: rgba(255, 255, 255, 0.8);
}

.preloader__text {
  color: var(--color-secondary-700);
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  width: 6.75rem;
  height: 6.75rem;
  justify-content: center;
  align-items: center;
}

.spinner__wrapper {
  overflow: hidden;
}

.spinner {
  border: 4px solid var(--color-secondary-200);
  border-top: 4px solid var(--color-secondary-700);
  border-right: 4px solid var(--color-secondary-700);
  background-color: var(--color-secondary-50);
  border-radius: 50%;
  width: 6.25rem;
  height: 6.25rem;
  animation: spin 1.5s linear infinite;
}

.card .preloader__wrapper {
  margin: -2rem;
  width: 100%;
  height: 100%;
}

.ui.popup {
  z-index: 1002 !important;
}

.badge__popup .preloader__wrapper {
  top: 0.3rem;
  left: 0.3rem;
  width: calc(100% - 0.6rem);
  height: calc(100% - 0.6rem);
}

.modal .preloader__wrapper {
  top: 0;
  width: 100%;
  height: 100%;
}

.report_content .preloader__wrapper {
  top: 0;
  width: 100%;
  height: 100%;
}

.badge__popup .spinner {
  width: 3.125rem;
  height: 3.125rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/*  modal ----------------------------------------------------------------------------------------------------------- */

.modal {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 0.375rem;
  max-width: 52rem;
  min-width: 47rem;
  overflow: auto;
  margin: 1.5rem 0;
}

.modal__header {
  display: flex;
  align-items: flex-start;
  padding: 1.5rem;
  background: var(--color-gray-50);
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 0.375rem 0.375rem 0 0;
}

.modal__header .display--xs-medium {
  line-height: 1.75rem;
}

.modal__header__left {
  display: flex;
  flex-direction: column;
}

.modal__header__centre {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.modal__header__right {
  display: flex;
  margin-left: auto;
  align-self: stretch;
}

.modal__header__closeicon {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  justify-content: end;
}

.modal__header__closeicon .svg {
  width: 0.75rem;
  height: 0.75rem;
  align-self: center;
}

.modal__content {
  flex-direction: column;
  padding: 2rem 1.5rem 2rem 1.5rem;
}

.modal__content__text {
  margin-bottom: 2rem;
}

.modal__footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  background: var(--color-gray-50);
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 0 0 0.375rem 0.375rem;
}

.modal__footer__text {
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.modal__footer__text__item {
  margin-bottom: 0.75rem;
}


.ui.modals.inverted.dimmer {
  background-color:rgba(0, 0, 0, 0.65) !important;
  opacity: 1 !important;
}

.ui.inverted.page.modals.dimmer.transition.visible.active {
  padding: 0;
}




.cmp_modal {
  display: flex;
  flex-direction: column;
  width: 75rem;
  height: calc(100vh - 12.5rem);
  background-color: var(--color-white);
}

.cmp_modal .modal__header {
  display: flex;
  align-items: flex-start;
  padding: 1.5rem;
  background: var(--color-gray-50);
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 0.375rem 0.375rem 0 0;
}

.cmp_modal .modal__header__left {
  display: flex;
  flex-direction: column;
}

.cmp_modal .modal__footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.5rem;
  background: var(--color-gray-50);
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 0 0 0.375rem 0.375rem;
  height: 5rem;
}

.cmp_modal .modal__content__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  overflow: auto !important;
  height: calc(100% - 11rem);
}

.cmp_modal .modal__content__container.center {
  align-items: center;
}

.cmp_modal .modal__content {
  flex-direction: column;
  padding: 2rem 1.5rem 2rem 1.5rem;
}


/*  badges ---------------------------------------------------------------------------------------------------------- */

.badge {
  border-radius: 0.8125rem;
  border: 1px solid;
  padding: 0.125rem 0.625rem;
  width: fit-content;
  box-sizing: border-box;
  white-space: nowrap;
  align-items: center;
  height: 1.625rem;
}

.badge--green {
  border: 1px solid var(--color-green-200);
  background-color: var(--color-green-100);
  color: var(--color-green-800) !important;
}

.badge--gray {
  border: 1px solid var(--color-gray-300);
  background-color: var(--color-gray-200);
  color: var(--color-gray-800) !important;
}

.badge--red {
  border-color: var(--color-red-200);
  background-color: var(--color-red-100);
  color: var(--color-red-900) !important;
}

.badge--yellow {
  border-color: var(--color-yellow-200);
  background-color: var(--color-yellow-100);
  color: var(--color-yellow-800) !important;
}

.badge--blue {
  border-color: var(--color-blue-200);
  background-color: var(--color-blue-100);
  color: var(--color-blue-800) !important;
}

.badge--solid_blue {
  border-color: var(--color-blue-600);
  background-color: var(--color-blue-700);
  color: var(--color-white) !important;
}

.badge--solid_green {
  border-color: var(--color-green-600);
  background-color: var(--color-green-700);
  color: var(--color-white) !important;
}

.badge--solid_orange {
  border-color: var(--color-orange-600);
  background-color: var(--color-orange-700);
  color: var(--color-white) !important;
}

.badge--solid_pink {
  border-color: var(--color-pink-500);
  background-color: var(--color-pink-600);
  color: var(--color-white) !important;
}

.badge--solid_purple {
  border-color: var(--color-purple-500);
  background-color: var(--color-purple-600);
  color: var(--color-white) !important;
}

.badge--solid_gray {
  border-color: var(--color-gray-400);
  background-color: var(--color-gray-500);
  color: var(--color-white) !important;
}

.badge .badge__icon {
  margin-left: 0.328rem;
  height: 0.781rem;
  width: 0.781rem;
  cursor: pointer;
}

.badge__icon--left {
  margin-right: 0.328rem;
  height: 1rem;
  width: 1rem;
}

.badge__icon--right {
  margin-left: 0.328rem;
  height: 0.844rem;
  width: 0.844rem;
}


/*  badge popups ---------------------------------------------------------------------------------------------------- */

.modal__popup {
  z-index: 1000;
}

.badge__popup {
  flex-direction: column;
  border: 1px solid var(--color-gray-200);
  padding: 1rem;
  margin: 0.25rem;
  background-color: var(--color-white);
  min-width: 12.5rem;
}

.badge__popup__title {
  margin-bottom: 0.75rem;
}

.badge__popup__option {
  border-top: 1px solid var(--color-gray-200);
  padding: 0.5rem 0;
  flex-direction: column;
}

.badge__popup__option .button.tertiary {
  height: 1.25rem;
}

.badge__popup__option .badge__popup__suboption {
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.badge__popup__suboption {
  flex-direction: column;
}

.badge__popup__button {
  margin-top: 0.75rem;
  width: fit-content;
}


/*  icons ----------------------------------------------------------------------------------------------------------- */

.icon__form {
  width: 1.5rem;
  height: 1.5rem;
}

.tablecontrol--primary-500 {
  width: 1rem;
  height: 1rem;
  color: var(--color-primary-500);
}

.icon.icon_error svg,
.error .icon_error svg {
  color: var(--color-red-600);
}


.new--blue-500 {
  color: var(--color-blue-500);
}

.checkmark--green-500 {
  width: 1rem;
  height: 1rem;
  color: var(--color-green-500);
  margin-right: 0.5rem;
  align-self: center;
}

.checkmark--green-500.notification {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0;
  margin-left: 0.75rem;
}

.icon__document_status {
  width: 1.5rem;
  height: 1.5rem;
}

.color--blue-500 {
  color: var(--color-blue-500);
}

.color--green-500 {
  color: var(--color-green-500);
}

.color--green-700 {
  color: var(--color-green-700);
}

.color--primary-500 {
  color: var(--color-primary-500);
}

.color--red-600 {
  color: var(--color-red-600);
}

.color--yellow-500 {
  color: var(--color-yellow-500);
}

.warning-icon--yellow {
  color: var(--color-yellow-400);
}

.icon__copy {
  width: 0.688rem;
  height: 0.813rem;
  align-self: center;
  margin-left: 0.375rem;
  color: var(--color-primary-500);
}

.icon__addindividual {
  width: 1rem !important;
  min-width: 1rem;
  align-self: center;
  height: 1rem !important;
  margin-left: 0.6rem !important;
}

.icon__lock {
  width: 0.6875rem !important;
  min-width: 0.6875rem;
  align-self: center;
  height: 0.875rem !important;
  margin-left: 0.5rem !important;
}

.icon__lock.color--gray-400 {
  color: var(--color-gray-400);
}

.icon__attachment {
  width: 0.6875rem;
  height: 1.375rem;
  color: var(--color-primary-500);
}

.icon__attachment.color--gray-300 {
  color: var(--color-gray-300);
}

.icon__field__error {
  color: var(--color-red-600) !important;
}

.icon__chevron__down {
  color: var(--color-primary-500);
  cursor: pointer;
  height: 0.75rem;
  width: 0.75rem;
  align-self: center;
}

.icon__delete__table {
  color: var(--color-primary-500);
  width: 0.875rem;
  height: 1.125rem;
  cursor: pointer;
}

.icon__status {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.75rem;
}

.icon__filter {
  position: relative;
  width: 0.75rem;
  height: 0.5rem;
  color: var(--color-gray-500);}

.icon__account_setting {
  width: 20px;
  height: 18px;
  color: var(--color-primary-300);
  margin-right: .8125rem;
}

.icon__image {
  width: 1.666rem;
  height: 1.666rem;
  color: #000000;
  margin-right: .416rem;
}

.icon__search svg,
.icon__clear svg {
  height: 1.1rem !important;
  width: 1.1rem !important;
}

.icon__search {
  color: var(--color-primary-500);
}

.icon__hamburger {
  width: 20px;
  height: 14px;
  color: var(--color-gray-300);
}

.icon__hamburger_close {
  width: 14px;
  height: 14px;
  color: var(--color-gray-300);
}


.icon__organization, .icon__settings, .icon__dashboard,
.icon__menuitem {
  width: 20px;
  height: 21px;
  color: var(--color-primary-300);
  margin-right: .8125rem;
}

.icon__nav_chevron {
  width: 0.75rem;
  height: 0.75rem;
  color: var(--color-gray-300);
}

.icon__dailycheck {
  width: 0.844rem;
  height: 0.938rem;
  margin-right: 0.391rem;
}

.icon__dailycheck_pass {
  color: var(--color-green-500);
}

.icon__dailycheck_fail, .icon__dailycheck_missing {
  color: var(--color-red-600);
}

.icon__api {
  width: 20px;
  height: 21px;
  color: var(--color-primary-300);
  margin-right: .8125rem;
}

.icon__api__breadcrumb {
  width: 16px;
  height: 16px;
  color: var(--color-primary-500);
}

.icon__signout {
  width: 18px;
  height: 19px;
  color: var(--color-primary-300);
  margin-right: .8125rem;
}

.icon__helpcenter {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--color-primary-300);
  margin-right: .8125rem;
}

.icon__alert {
  width: 30px;
  height: 30px;
  color: var(--color-gray-300);
}

.icon__x {
  width: 15px;
  height: 15px;
  color: var(--color-gray-900);
}

.icon__download {
  width: 30px;
  height: 30px;
  color: var(--color-primary-500);
}

.icon__notfound {
  width: 505px;
  height: 234px;
}

.message__error__icon {
  color: var(--color-red-600);
  margin: 0 0.625rem 0 0;
  align-self: flex-start;
}

.message__error__icon svg {
  width: 20px;
  height: 20px;
}

/*  notifications --------------------------------------------------------------------------------------------------- */


.notification__icon__status {
  margin-left: 0.75rem;
}

.notification__info {
  margin-bottom: 0.75rem
}



/*  simpletable ----------------------------------------------------------------------------------------------------- */

.simpletable {
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-50);
  padding: 2rem;
  margin-top: 1.5rem;
  border-radius: 0.375rem;
  border: 1px dashed var(--color-gray-300);
}

.simpletable__header {
  padding-bottom: 1rem;
}

.simpletable__header div:last-child {
  margin-left: auto;
}

.simpletable__row {
  border-top: 1px solid var(--color-gray-200);
  padding: 1rem 0;
}

.simpletable__row div:last-child {
  margin-left: auto;
}

.simpletable__row__icon {
  color: var(--color-primary-500);
  height: 1.125rem;
  margin-left: auto;
}

/*  miscellaneous --------------------------------------------------------------------------------------------------- */

.display-none {
  display: none !important;
}

/* https://learn.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal#remove-the-password-reveal-control */
::-ms-reveal {
  display: none;
}

/*  strengthometer -------------------------------------------------------------------------------------------------- */

.strengthometer {
  width: 100%;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.password_bar {
  display: block;
}

.password_bar div div {
  height: 4px !important;
}

.password_bar p {
  font-size: 0.75rem !important;
}

.password_bar p.level-0 {
  color: var(--color-gray-500) !important;
}

.password_bar p.level-1 {
  color: var(--color-red-600) !important;
}

.password_bar p.level-2 {
  color: var(--color-orange-500) !important;
}

.password_bar p.level-3 {
  color: var(--color-green-400) !important;
}

.password_bar p.level-4 {
  color: var(--color-green-600) !important;
}


/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 77rem) {
  .cmp_modal {
    width: 90%;
  }
}

@media only screen and (max-width: 70rem) {

  .modal {
    min-width: unset;
  }
}

@media only screen and (max-width: 53.125rem) {

  .app {
    flex-direction: column;
  }

  .nav {
    width: 100%;
  }

  .nav__header {
    justify-content: space-between;
  }

  .nav__header__hamburger {
    display: flex;
  }

  .nav__expanded {
    border-top: 2px solid var(--color-primary-500);
    padding: 0.75rem 14%;
  }

  .nav__expanded__options {
    padding-bottom: 2.25rem;
  }

  .nav__expanded__option {
    border-radius: 0.5rem;
  }

  .nav.nav__expanded--display {
    height: 100%;
  }

  .cont.nav__expanded--display {
    display: none;
  }

  .nav.nav__expanded--hide .nav__expanded{
    display: none;
  }

  .nav .profile_email {
    max-width: 18rem;
  }

  main {
    width: 100%;
  }

  .page {
    padding: 1.5rem;
  }

  .page__header {
    background-image: linear-gradient(var(--color-primary-700), var(--color-primary-700));
    background-size: 100% calc(100% - 3rem);
    background-repeat: no-repeat;
    margin: -1.5rem -1.5rem 1.5rem -1.5rem;
    padding: 1.5rem 1.5rem 0 1.5rem;
    color: var(--color-white);
  }

  .page__header--background2 {
    background-size: 100% 100%;
    padding-bottom: 3rem;
    margin-bottom: -2.5rem;
  }

  .page__header--background3 {
    background-size: 100% 100%;
    padding-bottom: 12rem;
    margin-bottom: -10.5rem;
  }

  .page__breadcrumb,
  h1 {
    display: none;
  }

  .page__back,
  .page__back svg {
    display: flex;
  }

  .launchcard {
    width: calc(50% - 2rem);
  }

  .launchcard__header {
    color: var(--color-white);
  }

  .details_card .tab .tab__item.current,
  .tab--detailscard .tab__item.current {
    color: var(--color-gray-900);
    border-color: var(--color-gray-900);
  }

  .details_card .tab .tab__item,
  .tab--detailscard .tab__item {
    color: var(--color-primary-500)
  }

  .tab__item.current {
    color: var(--color-white);
    border-color: var(--color-white);
  }

  .tab__item {
    color: var(--color-gray-300)
  }

  .modal {
    max-width: 90%;
  }

}

@media only screen and (max-width: 50rem) {

  .card__header__btns {
    display: none;
  }
  .card__footer--mobilebtns {
    display: flex;
  }
}


@media only screen and (max-width: 41rem) {

  .notification__icon__status {
    margin-left: 0.25rem;
  }

  .card {
    padding: 1rem;
  }

  .launchcard {
    width: calc(100% - 1.5rem);
    padding: 0 0.75rem 0 0.75rem;
  }

  .modal {
    max-width: 95%;
  }

}

@media only screen and (max-width: 35.5rem) {
  .textbox__wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 31.25rem) {

  .nav__header {
    padding: 0.75rem 1rem;
  }

  .page {
    padding: 1rem;
  }

  .page__header {
    background-image: linear-gradient(var(--color-primary-700), var(--color-primary-700));
    background-size: 100% calc(100% - 3rem);
    background-repeat: no-repeat;
    margin: -1rem -1rem 1rem -1rem;
    padding: 1rem 1rem 0 1rem;
    color: var(--color-white);
  }

  .page__header--background2 {
    background-size: 100% 100%;
    padding-bottom: 3rem;
    margin-bottom: -2.5rem;
  }

  .page__header--background3 {
    background-size: 100% 100%;
    padding-bottom: 12rem;
    margin-bottom: -10.5rem;
  }

  .offline {
    padding: 1.5rem;
  }

  .notfound {
    padding: 0;
  }

  .offline .card {
    width: 100%;
  }

  .tab__item, .tab__item.current {
    margin-right: 1rem;
  }

}

@media only screen and (max-width: 29rem) {

  .nav__expanded {
    padding: 0.75rem 3%;
  }

  .nav__expanded__options {
    padding-bottom: 0.5rem;
  }

}
