.icon__xicon {
    width: 20px;
    height: 20px;
    color: #DC2626;
  }
.icon__checkmark{
    width: 20px;
    height: 20px;
    color: #10B981;
}

.icon__skull {
    color: var(--color-gray-600);
}

.completion-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.ui.selection>.icon {
    display: flex;
    margin-left: auto !important;
}

#select__language {
    width: 19.125rem;
    border-top: none;
}

#select__language .detailsgroup {
    flex-direction: column;
    align-items: flex-start;
    border: none;
}

#select__language .detailsgroup__label {
    color: var(--color-gray-500);
    width: 9rem;
    margin-bottom: 0.25rem;
}

#select__language .detailsgroup__value {
    width: 100%;
}

#tbl_translations th:first-of-type {
    width: 200px !important;
}
