.progress-bar-container {
    width: 100%;
    height: 7px;
    margin-top: 7px;
    background-color: var(--color-gray-300);
    display: flex;
    border-radius: 10px;
    overflow: hidden;
}
  
.progress-bar {
    height: 100%;
    transition: width 0.5s ease-in-out;
    border-radius: 10px;
}

.progress-value {
    display: flex;
    margin-left: 5px;
}