.corporatescorecard__wrapper > div:not(:last-of-type) {
    margin-bottom: 3.125rem;
}

.corporatescorecard__chart {
    padding: 1rem;
    flex-direction: column;
    border: 0.0625rem solid var(--color-gray-400);
    border-radius: 0.5rem;
}

.corporatescorecard__charttitle {
    justify-content: center;
}

.corporatescorecard__helpicon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
}

.corporatescorecard__tooltip {
    padding: 0.5rem;
    flex-direction: column;
    white-space: nowrap;
}

.corporatescorecard__tooltip > div:first-of-type {
    font-weight: bold;
}

.corporatescorecard__tooltip > div > div:not(:first-of-type) {
    margin-left: 0.25rem;
    font-weight: bold;
}